<template lang="pug">
.base-info
  label.blue-header 基础信息
  DataForm.base-form(
    ref="form"
    v-model="value"
    type="grid"
    :gridspanNum="3"
    gridFlow="row"
    :inlineGrid="true"
    readonly
    :formFields="formFields")
  label.blue-header 总分表信息
  DataForm.base-form(
    ref="form"
    v-model="value"
    type="grid"
    :gridspanNum="3"
    gridFlow="row"
    :inlineGrid="true"
    readonly
    :formFields="totalSubFields")
  label.blue-header 价格方案
  DataForm.base-form(
    ref="form1"
    v-model="value"
    type="grid"
    :gridspanNum="3"
    gridFlow="row"
    :inlineGrid="true"
    readonly
    :formFields="priceFields")
    PriceTableForm(
      v-if="value.stepwise"
      slot="bodyFooter"
      v-model="value"
      readOnly
      ref="priceForm")
</template>

<script>
import PriceTableForm from '@/views/FileManage/PriceManage/components/PriceTableForm'

export default {
  name: 'BaseInfo',
  components: { PriceTableForm },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    formFields: {
      type: Array,
      default: () => ([])
    },
    totalSubFields: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    priceFields () {
      return this.priceBaseFields.concat(this.value.stepwise ? [] : this.normalPriceFields)
    }
  },
  data () {
    return {
      priceBaseFields: [{
        name: 'priceName',
        label: '价格方案',
        form: {
          tag: 'text'
        }
      },
      {
        name: 'stepwise',
        label: '阶梯计价',
        render: { type: 'yesOrNo' },
        form: {
          tag: 'text',
          colSpan: 2
        }
      }],
      normalPriceFields: [{
        name: 'water',
        label: '水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'text'
        }
      },
      {
        name: 'sewage',
        label: '污水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'text'
        }
      }, {
        name: 'tax',
        label: '水资源费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'text'
        }
      }]
    }
  },
  methods: {}
}
</script>

<style lang="sass" scoped>
.base-info
  .blue-header
    margin-left: 8px
    &:first-of-type
      margin-top: 12px
  .base-form
    width: 100%
    padding: 0 8px
</style>

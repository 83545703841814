<template lang="pug">
.full-box.main-box(v-loading="pageLoading")
  .header-box(v-if="show")
    .item-box
      label.blue-header 用户信息
      .form-content
        DataInfo(
          v-if="show"
          :data="data.customer"
          :fields="fields.customer")
    .item-box
      label.blue-header 结算信息
      DataInfo(
        :data="data.customer"
        :fields="fields.settle")
      label.blue-header 开票信息
      DataInfo(
        :data="data.customer"
        :fields="fields.invoice")
    .item-box
      label.blue-header 关联用水户
      .form-content
        DataTable.full-box(
          v-if="showUsewater && customerId"
          ref="table"
          highlightClickRow
          :showFilter="false"
          :hasPage="false"
          :data="usewaterList"
          :columns="fields.usewater"
          :settingButtonList="[]"
          @current-change="handleUsewaterChange")
  .footer-box.empty(
    v-if="!currentUsewater.id"
    v-loading="footerLoading")
    span 请先选择关联用水户
  el-tabs.footer-box(
    v-if="show && currentUsewater.id"
    v-loading="footerLoading"
    v-model="activeTab"
    type="border-card")
    el-tab-pane.tab-item-box(
      v-for="tab in tabsList"
      :key="tab.name"
      :name="tab.name"
      :label="tab.label")
      component(
        v-if="tab.name === activeTab"
        :is="tab.component"
        v-bind="tab.bind")
  TransferDetailDialog.dialog-box(
    v-model="visible"
    :data.sync="dialogData")
</template>

<script>
import { mapActions } from 'vuex'
import { composePromise, billPayStatusClassFunc, readingStatusClassFunc } from '@/utils/common.js'
import BaseInfo from '@/views/FileManage/UsewaterManage/components/BaseInfo'
import TransferDetailDialog from '@/views/FileManage/UsewaterManage/components/TransferDetailDialog'

export default {
  name: 'CustomerDetail',
  components: { TransferDetailDialog },
  computed: {
    customerId () {
      return this.$route.params.customerId
    }
  },
  data () {
    return {
      show: false,
      pageLoading: false,
      filterFields: [{
        name: 'customerId',
        label: '用户id',
        showable: false,
        form: {
          tag: 'input'
        }
      }],
      // 用户信息配置
      fields: {
        customer: [
          { name: 'customerCode', label: '用户编号' },
          { name: 'customerName', label: '用户姓名' },
          { name: 'orgName', label: '水厂' },
          { name: 'phone', label: '联系电话' },
          { name: 'address', label: '联系地址' },
          {
            name: 'idType',
            label: '证件类型',
            relation: 'idType',
            render: { type: 'select' }
          },
          { name: 'idNum', label: '证件号码' },
          {
            name: 'idAtts',
            label: '证件附件',
            render: { type: 'file', showType: 'image' }
          }
        ],
        usewater: [
          { name: 'usewaterCode', label: '用水户号' },
          { name: 'usewaterAddress', label: '用水地址' }
        ],
        settle: [
          { name: 'settlementName', label: '结算名称' },
          { name: 'settlementBank', label: '结算银行' },
          { name: 'settlementAccountNo', label: '账户号码' },
          { name: 'settlementPhone', label: '联系电话' }
        ],
        invoice: [
          { name: 'invoiceName', label: '开票名称' },
          { name: 'invoiceBank', label: '开票银行' },
          { name: 'invoiceBankAccount', label: '银行账号' },
          { name: 'invoiceTax', label: '纳税人识别号' },
          { name: 'invoicePhone', label: '联系电话' }
        ]
      },
      showUsewater: false,
      // 关联用水户数据
      usewaterList: [],
      // 当前选中用水户
      currentUsewater: {},

      data: {
        customer: {},
        usewater: {},
        meter: {}
      },
      footerLoading: false,
      activeTab: 'base',
      tabsList: [{
        name: 'base',
        label: '用水户信息',
        component: BaseInfo,
        bind: {
          value: {},
          formFields: [
            { name: 'usewaterCode', label: '用水户号', form: { tag: 'text' } },
            { name: 'usewaterAddress', label: '用水地址', form: { tag: 'text', colSpan: 2 } },
            { name: 'orgName', label: '水厂', form: { tag: 'text' } },
            { name: 'usewaterName', label: '联系人姓名', form: { tag: 'text' } },
            { name: 'usewaterPhone', label: '联系电话', form: { tag: 'text' } },
            {
              name: 'lastReadingNum',
              label: '最新行码',
              render: { type: 'padString', suffix: 'm³' },
              form: { tag: 'text' }
            },
            {
              name: 'billCycle',
              label: '缴费周期',
              relation: 'billCycle',
              render: { type: 'select' },
              form: { tag: 'text' }
            },
            {
              name: 'collectionId',
              label: '收款账户',
              relation: 'collections',
              render: { type: 'select' },
              form: {
                tag: 'text'
              }
            },
            {
              name: 'lastBillTime',
              label: '最后出账月份',
              render: { type: 'date', timeType: 'YYYY-MM' },
              form: { tag: 'text' }
            },
            {
              name: 'imprest',
              label: '预付费',
              render: (value) => {
                return value ? '启用' : '关闭'
              },
              form: {
                tag: 'text'
              },
              group: 'base'
            },
            {
              name: 'balance',
              label: '余额',
              form: {
                tag: 'text'
              },
              group: 'base'
            },
            {
              name: 'usenatureId',
              label: '用水性质',
              relation: 'usenature',
              render: { type: 'select' },
              form: { tag: 'text' }
            },
            {
              name: 'limitType',
              label: '阈值类型',
              relation: 'limitType',
              render: { type: 'select' },
              form: {
                tag: 'text'
              },
              group: 'base'
            },
            {
              name: 'limitValue',
              label: '阈值',
              form: {
                tag: 'text'
              }
            },
            {
              name: 'usewaterAtts',
              label: '附件',
              render: { type: 'file', showType: 'image' },
              form: {
                tag: 'text',
                colSpan: 3
              }
            }
          ],
          totalSubFields: [{
            name: 'totalSub',
            label: '总分表',
            form: {
              tag: 'text'
            },
            render: { type: 'yesOrNo' }
          },
          {
            name: 'totalSubType',
            label: '总分表类型',
            relation: 'totalSubType',
            form: {
              tag: 'text'
            },
            render: { type: 'select' }
          },
          {
            name: 'wastage',
            label: '损耗量',
            form: {
              tag: 'text'
            }
          },
          {
            name: 'totalUsewaterId',
            label: '所属总表',
            form: {
              tag: 'text',
              options: []
            },
            render: { type: 'select' }
          },
          {
            name: 'subMeterType',
            label: '子表类型',
            form: {
              tag: 'text',
              colSpan: 2
            },
            relation: 'subMeterType',
            render: { type: 'select' }
          }]
        }
      }, {
        name: 'meter',
        label: '水表信息',
        component: 'DataForm',
        bind: {
          value: {},
          type: 'row',
          colspanNum: 3,
          dot: true,
          formFields: [{
            name: 'meterNo',
            label: '水表编号',
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'meterType',
            label: '水表类型',
            relation: 'meterType',
            form: { tag: 'text' },
            render: { type: 'select' },
            group: 'meter'
          },
          {
            name: 'diameter',
            label: '口径',
            relation: 'diameter',
            form: { tag: 'text' },
            render: { type: 'select' },
            group: 'meter'
          },
          {
            name: 'meterOutNo',
            label: '水表外码',
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'installNum',
            label: '装表行码',
            render: { type: 'padString', suffix: 'm³' },
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'maxnumber',
            label: '量程',
            render: { type: 'padString', suffix: 'm³' },
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'installDate',
            label: '装表日期',
            render: { type: 'date' },
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'factory',
            label: '厂家',
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'meterSealno',
            label: '铅封号',
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'meterSelfno',
            label: '出厂编码',
            form: { tag: 'text' },
            group: 'meter'
          },
          {
            name: 'remark',
            label: '备注',
            form: { tag: 'text', colSpan: 2 },
            group: 'meter'
          },
          {
            name: 'meterAtts',
            label: '水表附件',
            form: { tag: 'text', colSpan: 3 },
            render: { type: 'file', showType: 'image' },
            group: 'meter'
          }]
        }
      }, {
        name: 'meterRecord',
        label: '抄表信息',
        component: 'DataTable',
        bind: {
          key: 'meterRecord',
          ref: 'meterRecord',
          serverSide: true,
          resource: '/meterReading/list',
          showFilter: false,
          filterFields: [{
            name: 'usewaterId',
            label: '用户',
            showable: false,
            form: {}
          }, {
            name: 'customerId',
            label: '用户',
            showable: false,
            form: {}
          }],
          extraFilterBy: {
            usewaterId: '',
            customerId: ''
          },
          columns: [
            { name: 'readingTime', label: '抄表时间', minWidth: 140, render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' } },
            { name: 'readingStatus', label: '抄表状态', relation: 'readingStatus', render: { type: 'select' } },
            { name: 'lastReadingNum', label: '上次抄表行码', minWidth: 140 },
            { name: 'thisReadingNum', label: '本次抄表行码', minWidth: 140 },
            { name: 'quantity', label: '水量' },
            { name: 'estimate', label: '估抄', render: { type: 'yesOrNo' } },
            { name: 'meterCondition', label: '表况', relation: 'meterStatus', render: { type: 'select' } },
            { name: 'readingStaffName', label: '抄表员', minWidth: 100 },
            { name: 'meterNo', label: '水表编号' },
            { name: 'meterType', label: '水表类型', relation: 'meterType', render: { type: 'select' } },
            { name: 'orgName', label: '水厂', minWidth: 140 }
          ],
          cellClassNameFunc: readingStatusClassFunc
        }
      }, {
        name: 'bill',
        label: '账单信息',
        component: 'DataTable',
        bind: {
          key: 'bill',
          ref: 'bill',
          serverSide: true,
          resource: '/bill/compreQuery',
          showFilter: false,
          filterFields: [{
            name: 'usewaterId',
            label: '用户',
            showable: false,
            form: {}
          }, {
            name: 'customerId',
            label: '用户',
            showable: false,
            form: {}
          }],
          extraFilterBy: {
            usewaterId: '',
            customerId: ''
          },
          columns: [
            { name: 'billMonth', label: '账期', minWidth: 80 },
            { name: 'billNo', label: '账单编号', minWidth: 130 },
            { name: 'quantity', label: '水量' },
            { name: 'totalAmount', label: '总金额', minWidth: 100 },
            { name: 'billPayStatus', label: '缴费状态', relation: 'billPayStatus', render: { type: 'select' } },
            { name: 'waterAmount', label: '水费总额', minWidth: 100 },
            { name: 'sewageAmount', label: '污水费总额', minWidth: 100 },
            { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 100 },
            { name: 'penaltyAmount', label: '滞纳金总额', minWidth: 100 },
            { name: 'penaltyDays', label: '滞纳天数' },
            { name: 'billCreateTime', label: '账单产生日期', minWidth: 120, render: { type: 'date' } },
            { name: 'payType', label: '缴费方式', relation: 'payType', render: { type: 'select' } },
            { name: 'payTime', label: '缴费日期', minWidth: 120, render: { type: 'date' } }
          ],
          cellClassNameFunc: billPayStatusClassFunc
        }
      }, {
        name: 'meterChangeLog',
        label: '换表记录',
        component: 'DataTable',
        bind: {
          key: 'meter',
          ref: 'meter',
          // serverSide: true,
          resource: '',
          showFilter: false,
          hasPage: false,
          transformResponse: (response) => {
            const data = response.data || []
            response.data = {
              total: data.length,
              data: data
            }
            return response
          },
          columns: [
            { name: 'installDate', label: '装表日期', minWidth: 120, render: { type: 'date' } },
            { name: 'meterNo', label: '水表编号', minWidth: 100 },
            {
              name: 'meterType',
              label: '水表类型',
              minWidth: 80,
              relation: 'meterType',
              render: { type: 'select' }
            },
            {
              name: 'diameter',
              label: '口径',
              minWidth: 80,
              relation: 'diameter',
              render: { type: 'select' }
            },
            { name: 'meterOutNo', label: '水表外码', minWidth: 100 },
            { name: 'installNum', label: '装表行码', minWidth: 100 },
            { name: 'meterSealno', label: '铅封号', minWidth: 100 },
            { name: 'meterSelfno', label: '出厂编码', minWidth: 100 },
            { name: 'remark', label: '备注', minWidth: 150 }
          ]
        }
      }, {
        name: 'transfer',
        label: '过户记录',
        component: 'DataTable',
        bind: {
          key: 'transfer',
          ref: 'transfer',
          // serverSide: true,
          resource: '',
          showFilter: false,
          hasPage: false,
          transformResponse: (response) => {
            const data = response.data || []
            response.data = {
              total: data.length,
              data: data
            }
            return response
          },
          defaultSortBy: [{ prop: 'transferTime', order: 'desc' }],
          settingButtonList: [],
          columns: [
            { name: 'transferTime', label: '过户日期', minWidth: 120, render: { type: 'date' } },
            {
              name: 'oldcustomerName',
              label: '过户前用户',
              minWidth: 160,
              render: (value, field, data) => {
                const { oriCustomerName, oriCustomerCode } = data
                return `${oriCustomerName}(${oriCustomerCode})`
              }
            },
            {
              name: 'customerName',
              label: '过户后用户',
              minWidth: 160,
              render: (value, field, data) => {
                const { customerName, customerCode } = data
                return `${customerName}(${customerCode})`
              }
            },
            { name: 'operator', label: '操作人', minWidth: 100 }
          ],
          operateButtonList: [{
            label: '详情',
            name: 'detail',
            type: 'text',
            textType: 'primary',
            func: this.handleTransferDetail
          }]
        }
      }],
      // 过户详情数据
      visible: false,
      dialogData: {}
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['idType', 'readingStatus', 'meterStatus', 'meterType', 'billPayStatus', 'payType', 'billCycle', 'usenature', 'diameter', 'collections', 'totalSubType', 'subMeterType', 'limitType'])
        .then(res => {
          this.$renderRelationColumns(res, [
            this.fields.customer,
            this.tabsList[0].bind.formFields,
            this.tabsList[0].bind.totalSubFields,
            this.tabsList[1].bind.formFields,
            this.tabsList[2].bind.columns,
            this.tabsList[3].bind.columns,
            this.tabsList[4].bind.columns
          ])
          this.show = true
        })
    },
    // 获取关联用水户
    getUsewaterList () {
      this.$get({
        url: '/usewater/customerUsewaterlist',
        params: { customerId: this.customerId }
      })
        .then(res => {
          this.showUsewater = true
          this.usewaterList = res && res.data ? res.data : []
        })
        .catch(() => {
          this.showUsewater = true
        })
    },
    // 当前关联用水户的选中change
    handleUsewaterChange (row) {
      const step = [
        this.renderData,
        this.getUsewaterData
      ]
      this.footerLoading = true
      composePromise(step)(row)
        .catch(e => {
          console.error(e)
          this.footerLoading = false
        })
    },
    // 获取用水户信息
    getUsewaterData ({ id }) {
      return new Promise((resolve, reject) => {
        if (!id) {
          reject(new Error('error message: missing id'))
        } else {
          this.$get({
            url: `/usewater/get/${id}`
          })
            .then(res => {
              const meter = res.data.meter
              const usewater = res.data
              resolve({ usewater, meter })
            })
            .catch(reject)
        }
      })
    },
    renderData (data, row) {
      this.data.usewater = data.usewater
      this.data.meter = data.meter
      // 处理用水户信息和水表信息的值
      this.tabsList[0].bind.value = { ...data.usewater, ...data.usewater.price }
      this.tabsList[1].bind.value = data.meter
      // 处理抄表记录/账单记录/换表记录的过滤
      const tableTabIndexs = [2, 3]
      tableTabIndexs.forEach(index => {
        const target = this.tabsList[index].bind.extraFilterBy
        target.usewaterId = row.id
        target.customerId = this.customerId
      })
      this.tabsList[4].bind.resource = `/meterChangeLog/list?usewaterId=${row.id}&customerId=${this.customerId}`
      this.tabsList[5].bind.resource = `/transfer/list?usewaterId=${row.id}`
      this.$nextTick(() => {
        // 调用刷新
        const regetDataTable = ['meterRecord', 'bill', 'meter', 'transfer']
        regetDataTable.forEach(refname => {
          this.$refs[refname] && this.$refs[refname].reRenderTableData()
        })
      })
      // 更新当前用水户
      this.currentUsewater = row
      this.footerLoading = false
    },
    // 获取用户信息
    getCustomerData ({ customerId }) {
      return new Promise((resolve, reject) => {
        if (!customerId) {
          reject(new Error('error message: missing customerId', customerId))
        } else {
          this.$get({
            url: `/customer/get/${customerId}`
          })
            .then(customer => {
              if (!customer) {
                reject(customer)
                return
              }
              this.getTotalUsewaterIdOptions(customer.data.orgId)
              resolve({ customer: customer.data })
            })
            .catch(reject)
        }
      })
    },
    // 获取整体数据
    getData () {
      this.pageLoading = true
      this.getCustomerData(this.$route.params || {})
        .then(data => {
          this.data.customer = data.customer
          this.pageLoading = false
        })
        .catch(e => {
          console.error(e)
          this.pageLoading = false
        })
    },
    // 过户详情
    handleTransferDetail ({ data }) {
      this.dialogData = data
      this.visible = true
    },
    // 获取总表数据
    getTotalUsewaterIdOptions (orgId) {
      return new Promise((resolve, reject) => {
        this.$get({ url: `/usewater/getCustomerTotalUsewater?orgId=${orgId}` })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.tabsList[0].bind.totalSubFields[3].options = res.data ? res.data.map(item => {
              return { value: item.id, label: `${item.customerName}(${item.customerCode})` }
            }) : []
            resolve()
          })
          .catch(reject)
      })
    }
  },
  created () {
    this.renderRelation()
    this.getData()
    this.getUsewaterList()
  }
}
</script>

<style lang="sass" scoped>
.main-box
  display: flex
  flex-direction: column
  background-color: #eceff0
  .header-box
    width: 100%
    max-height: 320px
    display: flex
    flex-direction: row
    .item-box
      flex: 1
      padding: 8px
      background-color: #fff
      overflow: auto
      &+.item-box
        margin-left: 8px
      .form-content
        height: calc( 100% - 28px )
        overflow-y: auto
  .footer-box
    flex: 1
    margin-top: 8px
    overflow: auto
    .tab-item-box
      height: 100%
      overflow-y: auto
      overflow-x: hidden
    &.empty
      background-color: #fff
      display: flex
      align-items: center
      justify-content: center
</style>
<style scoped>
.footer-box >>> .el-tabs__content {
  height: calc(100% - 40px);
  padding: 0;
  overflow-y: auto;
}
.footer-box >>> #pane-usewater {
  padding: 8px;
}
</style>

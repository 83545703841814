<template lang="pug">
el-dialog.dialog-box(
  title="详情"
  append-to-body
  width="50%"
  :visible.sync="visible"
  :before-close="handleDialogClose")
  .form-box
    DataForm(
      ref="form"
      v-model="data"
      type="grid"
      :gridspanNum="2"
      gridFlow="row"
      :inlineGrid="true"
      labelWidth="130px"
      :formFields="dialogFormFields")
</template>

<script>

export default {
  name: 'TransferDetailDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      dialogFormFields: [{
        name: 'transferTime',
        label: '过户日期',
        form: { tag: 'text', dot: true },
        render: { type: 'date' }
      }, {
        name: 'operator',
        label: '操作人',
        form: { tag: 'text', dot: true }
      }, {
        name: 'oldcustomerName',
        label: '过户前用户',
        form: { tag: 'text', dot: true },
        render: (value, field, data) => {
          const { oriCustomerName, oriCustomerCode } = data
          return `${oriCustomerName}(${oriCustomerCode})`
        }
      }, {
        name: 'customerName',
        label: '过户后用户',
        minWidth: 160,
        form: { tag: 'text', dot: true },
        render: (value, field, data) => {
          const { customerName, customerCode } = data
          return `${customerName}(${customerCode})`
        }
      }, {
        name: 'transferAtts',
        label: '附件',
        form: { tag: 'text', dot: true, colSpan: 2 },
        render: { type: 'file', showType: 'image' }
      }, {
        name: 'transferRemark',
        label: '备注',
        form: { tag: 'text', dot: true, colSpan: 2 }
      }]
    }
  },
  methods: {
    handleDialogClose () {
      this.$emit('update:data', {})
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>

</style>
